import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/base/base.component';

import { ButtonComponent } from '../button/button.component';
import { ErrorComponent } from '../error/error.component';
import { InputComponent } from '../input/input.component';
import { UserService } from '../../core/modules/user/services/user.service';
import { TooltipDirective } from '../tooltip/tooltip.directive';
import { ErrorHandlingService } from '../../core/services/error-handling/error-handling.service';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-restore-password-form',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ErrorComponent, InputComponent, ReactiveFormsModule, TranslateModule, TooltipDirective],
  templateUrl: './restore-password-form.component.html',
  styleUrl: './restore-password-form.component.scss'
})
export class RestorePasswordFormComponent extends BaseComponent {
  public isSuccessReset: boolean;
  public loading: boolean;

  public form: UntypedFormGroup;

  @Output() onLoginClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly errorService: ErrorHandlingService
  ) {
    super();
  }

  ngOnInit(): void {
    this._buildForm();
  }

  reset(): void {
    this.loading = true;

    const { email } = this.form.value;

    this.userService.resetPassword(email).pipe(
      takeUntil(this.destroy$),
    ).subscribe({
      next: () => {
        this.loading = false;
        this.isSuccessReset = true;

        this.showAttemptLimitWarning(true);
      },

      error: (error: HttpErrorResponse) => {
        this.isSuccessReset = false;
        this.loading = false;

        this.showAttemptLimitWarning();

        this.errorService.handleHttpErrors({
          errorResponse: error,
          form: this.form
        });
      }
    });
  }

  showAttemptLimitWarning(shouldReset = false): void {
    const minAttemptsWarning = 5; // Number of attempts to show warning message
    const maxAttempts = 7; // Number of attempts to block the form
    const attemptsName = 'restorePasswordNumber';

    this.errorService.handleAttemptsNumber({
      endpointName: attemptsName,
      minAttemptsWarning,
      maxAttempts,
      reset: shouldReset
    });
  }

  inputChange(): void {
    this.isSuccessReset = false;
  }

  handleLoginClick(): void {
    this.onLoginClick.emit();

    this.goToLogin();
  }

  public goToLogin(): void {
    this.router.navigate(['/login']);
  }

  public submit(): void {
    this.reset();
  }

  private _buildForm(): void {
    this.form = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.email, Validators.required])
    });

    this.route.queryParams.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      if (params.email) {
        this.form.patchValue({ email: params.email });
      }
    });
  }
}
